<template>
  <div class="example">
    <b-card>
      <b-card-header>
        <b-card-title>Raw Materials Movement</b-card-title>
        <!-- datepicker -->

        <!-- datepicker -->
      </b-card-header>
      <apexcharts
        height="350"
        type="bar"
        :options="chartOptions"
        :series="chartData"
      ></apexcharts>
    </b-card>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { BCard, BCardTitle, BCardHeader } from "bootstrap-vue";

export default {
  name: "Chart",
  props: {
    raw: {
      type: Array,
    },
  },
  components: {
    apexcharts: VueApexCharts,
    BCard,
    BCardHeader,
    BCardTitle,
  },

  computed: {
    chartOptions() {
      const simplifyDate = (dateStr) => {
        const date = new Date(dateStr);
        const day = date.getDate();
        const month = date.getMonth() + 1; // months are 0-indexed in JavaScript
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
      };
      return {
        xaxis: {
          categories: this.raw.map((entry) => simplifyDate(entry.movementDate)),
          dataLabels: {
            offsetY: 5,
            style: {
              colors: "#f8f8f8",
              fontSize: "0.857rem",
            },
          },
          title:{
            text: "Amount",
            style: {
            color:" #f8f8f8",
            fontSize: '14px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 600,
            cssClass: 'apexcharts-xaxis-title ',
          },
        },
        },
        yaxis:{
          title:{
            text: "Date",
            style: {
            color:" #f8f8f8",
            fontSize: '14px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 600,
            cssClass: 'apexcharts-xaxis-title ',
          },
        },
        }
      };
    },
    chartData() {
      return [
        {
          name: "Movement Quantity",
          data: this.raw.map((entry) => entry.quantityMoved),
        },
      ];
    },
  },
};
</script>
