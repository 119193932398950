<template>
  <div class="example">
    <b-card>
      <b-card-header>
        <b-card-title>Top 10 Moving Items</b-card-title>
      </b-card-header>
      <apexcharts
        height="350"
        type="bar"
        :options="chartOptions"
        :series="chartData"
      ></apexcharts>
    </b-card>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { BCard, BCardTitle, BCardHeader } from "bootstrap-vue";

export default {
  name: "Chart",
  props: {
    semi: {
      type: Array,
    },
  },
  components: {
    apexcharts: VueApexCharts,
    BCard,
    BCardHeader,
    BCardTitle,
  },

  computed: {
    chartOptions() {
      return {
        xaxis: {
          categories: this.semi.map((item) => item.name),
          dataLabels: {
            offsetY: 5,

            style: {
              colors: "#f8f8f8",
              fontSize: "0.857rem",
            },
          },
          title: {
            text: "Date",
            style: {
              color: " #f8f8f8",
              fontSize: "14px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              cssClass: "apexcharts-xaxis-title ",
            },
          },
        },
        yaxis: {
          title: {
            text: "Amount",
            style: {
              color: " #ffffff",
              fontSize: "14px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              cssClass: "apexcharts-xaxis-title text-white",
            },
          },
          min: 0,
          tickAmount: 10,
          labels: {
            formatter: (val) => {
              return val.toFixed(0);
            },
          },
        },
      };
    },
    chartData() {
      return [
        {
          name: "Top 10 Moved Items",
          data: this.semi.map((item) => item.qty),
        },
      ];
    },
  },
};
</script>
