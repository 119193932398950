<template>
  <b-container>
    <div class="d-flex justify-content-center mb-1">
      <b-spinner
        variant="success"
        label="Spinning"
        block
        v-if="loading"
        style="width: 3rem; height: 3rem"
      ></b-spinner>
    </div>
    <b-row>
      <b-col cols="12">
        <ChartjsBarChart :finished="transactionCounts" v-if="!loading" />
      </b-col>
      <b-col cols="12">
        <InventoryChart :semi="topTenItems" v-if="!loading" />
      </b-col>
      <!-- <b-col cols="12">
      <RawMaterial :raw="uniqueItemsPerType" v-if="!loading" />
    </b-col> -->
    </b-row>
  </b-container>
</template>

<script>
import ChartjsBarChart from "./ChartjsBarChart.vue";
import InventoryChart from "./InventoryChart.vue";
import RawMaterial from "./RawMaterial.vue";
import { BContainer, BRow, BCol, BSpinner } from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    BContainer,
    InventoryChart,
    BRow,
    RawMaterial,
    BCol,
    BSpinner,
    ChartjsBarChart,
  },

  computed: {
    ...mapGetters("inventoryTransactionModule", {
      transactions: "transactions",
      loading: "loading",
    }),
    ...mapGetters("warehouseModule", {
      warehouses: "warehouse",
      warehouseLoading: "loading",
    }),

    ...mapGetters("productModule", {
      productList: "product",
      loading: "loading",
    }),

    ...mapGetters("rawmaterialModule", {
      materials: "rawmaterials",
      loading: "loading",
    }),

    transactionCounts() {
      const counts = {};
      this.transactions.forEach((transaction) => {
        const type = transaction.type;
        counts[type] = (counts[type] || 0) + 1;
      });
      return counts;
    },
    totalQuantities() {
      const quantities = {};
      this.transactions.forEach((transaction) => {
        const type = transaction.type;
        const quantity = transaction.quantity;
        quantities[type] = (quantities[type] || 0) + quantity;
      });
      return quantities;
    },
    topTenItems() {
      const transactions = this.transactions;
      const productList = this.productList;
      const rawMaterials = this.materials; // Accessing the raw materials
      console.log("Raw Materials", rawMaterials);
      const itemIdToQty = {};

      transactions.forEach((transaction) => {
        transaction.inventoryTransactionLine.forEach((line) => {
          if (!itemIdToQty[line.itemId]) {
            itemIdToQty[line.itemId] = 0;
          }
          itemIdToQty[line.itemId] += line.qty;
        });
      });

      const sortedItemIds = Object.keys(itemIdToQty)
        .sort((a, b) => itemIdToQty[b] - itemIdToQty[a])
        .slice(0, 10);

      const graphData = sortedItemIds.map((itemId) => {
        let item = productList.find((p) => p.id === itemId);
        if (!item) {
          item = rawMaterials.find((m) => m.id === itemId);
        }
        return {
          name: item ? item.name : "Unknown Item",
          qty: itemIdToQty[itemId],
          itemId,
        };
      });

      return graphData;
    },

    uniqueItemsPerType() {
      const uniqueItems = {};
      this.transactions.forEach((transaction) => {
        const type = transaction.type;
        const itemID = transaction.itemID;
        uniqueItems[type] = uniqueItems[type] || new Set();
        uniqueItems[type].add(itemID);
      });
      for (const type in uniqueItems) {
        uniqueItems[type] = Array.from(uniqueItems[type]);
      }
      return uniqueItems;
    },
  },

  methods: {
    ...mapActions("inventoryTransactionModule", ["getTransactionListAction"]),
    ...mapActions("warehouseModule", ["getWarehouseListAction"]),
    ...mapActions("productModule", ["getProductListAction", "removeProductAction"]),
    ...mapActions("rawmaterialModule", ["getRawMaterialListAction"]),
  },

  async mounted() {
    await this.getRawMaterialListAction()
      .then(() => {})
      .catch((response) => {
        console.log(response);
      });
    await this.getWarehouseListAction()
      .then(() => {})
      .catch((response) => {
        console.log(response);
      });

    await this.getTransactionListAction()
      .then(() => {})
      .catch((response) => {
        console.log(response);
      });

    await this.getProductListAction()
      .then(() => {})
      .catch((response) => {
        console.log(response);
      });
  },
};
</script>
